.event-listing-route {
  .filters {
    display: flex;
    margin-bottom: 48px;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    padding: 15px 0;

    @include small {
      justify-content: center;
      flex-direction: column;
    }

    a.filter {
      position: relative;
      top: 1px; //this hides the parent element's border
      border: none;
      border-bottom: 5px solid transparent; //1px is hidden
      display: flex;
      margin: 0 24px;
      padding: 17px 0;
      text-decoration: none;
      @extend .small-text-bold;
      background-color: transparent;
      color: $black;
      text-transform: uppercase;

      @include small {
        margin: 0 14px;
        border-bottom: 1px solid $black;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        @extend .link-active;
        border-bottom-color: $red;
        font-weight: 900;

        @include small {
          border-bottom-width: 5px;
        }
      }

      &:not(.active) {
        &:hover {
          @extend .link-hover-state;
        }

        &:active {
          @extend .link-active-state;
          border-bottom-color: $red;
        }
      }
    }
  }
}
