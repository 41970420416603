@import "@/styles/globals.scss";
@import '../../styles/defaults/css-properties';

/**
 * Colors
 */
$red: #d22000;

$black: #2b292b;
$real-black: #000000;
$dark-gray: #3b3b3b;
$medium-gray: #898989;
$light-gray: #d9d9d9;
$lighter-gray: #cfcece;
$very-light-gray: #ececec;

$body-color: $black;
$primary-color-darker: $black;

// Radius
$radius: 0;

//Header
$header-height-large: 112px;
$header-height-medium: 112px;
$header-height-small: 80px;

$cart-background-color: #ececec;
$cart-divider: 1px solid var(--light-gray);

// Defaults
@import '../../styles/defaults/index';
@import '../../styles/helpers/index';

%label {
  font-family: $primary-font;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 4px 0;
  line-height: 13px;
}

%input {
  border: 2px solid $dark-gray;
  line-height: 44px;
  padding: 0 12px 0 16px;
}

%button {
  padding: 12px 30px;
  font-size: 15px;
  line-height: 24px;
}

%primary-button {
  letter-spacing: 0.03em;
}

%secondary-button-hover,
%secondary-button {
  background-color: $white;
  color: $black;
  border: 2px solid $black;
}

// Base elements
@import 'page';
@import 'elements';
@import 'links-and-buttons';
@import 'form-inputs';
@import 'modals';
@import 'messages';
@import 'badges';

// Components
@import '_header';
@import '_footer';
@import 'filters';
@import 'actions';
@import 'cart';
@import 'selection';
@import 'steppers-3';
@import 'calendar';
@import 'members-menu';
@import 'legend';

// Routes
@import 'checkout';
@import 'completed';
@import 'event-list';
@import 'buy-membership';
@import 'gift-of-membership';
@import 'forgot-membership';
@import 'event';
@import 'resend-tickets';
@import 'manage-order';
@import 'home';
@import 'my-account';

// Stylesheets specific to this tenant;

@mixin topSeparator(
  $borderColor: $light-gray,
  $borderWidth: 1px,
  $borderStyle: solid,
  $spacingAboveLine: $spacing-l,
  $spacingBelowLine: $spacing-l
) {
  padding-top: $spacingBelowLine;
  border-top: $borderWidth $borderStyle $borderColor;
  margin-top: $spacingAboveLine;
}

@mixin bottomSeparator(
  $borderColor: $light-gray,
  $borderWidth: 1px,
  $borderStyle: solid,
  $spacingAboveLine: $spacing-l,
  $spacingBelowLine: $spacing-l
) {
  padding-bottom: $spacingAboveLine;
  border-bottom: $borderWidth $borderStyle $borderColor;
  margin-bottom: $spacingBelowLine;
}

// Components
@import 'sass/icons';
@import 'sass/elements';
@import 'sass/cart';
@import 'sass/filters';
@import 'sass/fonts';
@import 'sass/footer';
@import 'sass/header';
@import 'sass/modals';
@import 'sass/surveys';

// Page specific
@import 'sass/page'; //base page.
@import 'sass/checkout';
@import 'sass/completed';
@import 'sass/event';
@import 'sass/event-listing';
@import 'sass/forgot-password';
@import 'sass/home';
@import 'sass/membership';
@import 'sass/resend';
