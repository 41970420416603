.site-header {
  @include responsiveProperty('height', 80px, 112px, 112px);
  padding: 8px 8px 8px 0;
  border-bottom: 1px solid $very-light-gray;

  .logo {
    width: 298px;

    img {
      width: 100%;
    }

    @include small {
      width: 182px;
    }

    @include mediumOnly {
      width: 250px;
    }
  }

  .account-links {
    text-align: right;
    display: flex;
    align-items: flex-end;
    margin-right: 0;

    @include smallAndMedium {
      flex-direction: column-reverse;
    }

    a,
    button {
      font-family: $primary-font;
      display: inline-block;
      color: $primary-color;
      font-weight: bold;
      font-size: 18px;
      line-height: 29px;
      margin-bottom: 0;
      text-decoration: none;
      padding: 0;

      &:hover {
        text-decoration: none;
      }

      @include large {
        margin-right: $spacing-l;
        padding: 0;
      }
    }
  }

  @include mediumOnly {
    .short-label {
      display: block;
    }

    .long-label {
      display: none;
    }
  }

  //menu
  details.member-menu {
    summary.member-menu-icon {
      svg {
        stroke: $black;
      }

      @include small {
        border-left: 1px solid $very-light-gray;
        border-radius: 0;
      }
    }

    .greeting-title {
      font-family: $primary-font;
      font-size: 11px;
      line-height: 13px;
    }

    .greeting-username {
      font-size: 21px;
      line-height: 140%;
    }

    button {
      font-family: $primary-font;
      font-size: 18px;
      line-height: 23px;
    }
  }
}
