.home-route {
  h1 {
    text-align: center;
    margin: 67px 0;

    @include small {
      margin: 32px 0;
      text-align: left;
    }
  }

  h2 {
    border: 0;
    margin: 0 0 10px 0;

    @include small {
      font-size: 24px;
      line-height: 100%;
    }
  }

  form.login {
    .actions a {
      font-family: $primary-font;
      font-size: 16px;
    }
  }

  .member.login .form-input-group {
    margin-top: 35px;

    label:last-of-type {
      margin-bottom: 0;
    }
  }

  .options > section,
  .guest-shopping {
    button,
    > a.button {
      padding: 12px 30px;
      width: max-content;
      text-align: left;
    }

    @include small {
      &:last-of-type {
        border: none;
      }
    }
  }

  .guest-shopping {
    a.button:last-of-type {
      margin-top: 16px;
    }
  }

  @include mediumOnly {
    section.third {
      margin: 0;
      padding: 0;
      border: 0;
    }
  }

  .block.before-resend-tickets {
    margin: 50px 0 0 0;
    padding: 25px;
    border: 2px solid $black;

    @include small {
      margin: 0 0 50px 0;
    }
  }

  .resend-tickets {
    font-family: $primary-font;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 $spacing-l * 2 0;

    @include mediumAndLarge {
      margin: 50px 0 0 0;
    }
  }

  ul {
    padding-left: 0.7em;
  }
}
