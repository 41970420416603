.content-detail-page.membership {
  .redeem-membership-route,
  .buy-membership {
    .section-header {
      margin-bottom: $spacing-l;
      margin-top: 32px;
    }

    .membership-level {
      .radio-item .label {
        margin-bottom: 0;
      }

      .section-header {
        margin-top: 0;
      }
    }

    .auto-renew .section-header {
      margin-bottom: 21px;
    }

    //form labels
    label.form-input:not(.checkbox):not(.radio-item) > .label {
      font-weight: bold;
    }

    .radio-group .radio-item {
      margin-bottom: 48px;
    }

    .member-names {
      &.form-input {
        > .label {
          @extend h3;
          text-transform: none;
        }
      }

      .radio-items {
        margin-top: $spacing-l;

        .radio-item {
          margin-bottom: 0;
        }
      }

      .name-fields {
        margin-top: 24px;

        &:last-of-type {
          margin-top: $spacing-l;
        }
      }
    }
  }
}
