.event-listing-route {
  h1 {
    @include small {
      margin-top: $spacing-l;
    }
  }

  article.event {
    @include large {
      grid-auto-columns: calc(35% - 24px) 1fr;
    }

    h2 {
      border: 0;

      @include mobileExperience {
        margin-top: 16px;
      }
    }

    figure {
      border: none;
      display: inline-block;
      vertical-align: top;
      padding-right: 0;

      @include large {
        margin-right: 24px;

        img {
          height: auto;
        }
      }
    }

    a.title-link {
      text-decoration: none;

      h2 {
        margin-bottom: 8px;
      }
    }

    .subtitle {
      font-family: $primary-font;
      margin: 8px 0 16px;
      font-weight: bold;
      font-size: 15px;
      line-height: 24px;
      text-transform: uppercase;
    }

    div.button.primary {
      margin-top: 15px;
      color: $primary-color;
      background: transparent icon('arrowRightIcon', $black) no-repeat center right;
      font-family: $primary-font;
      font-size: 18px;
      line-height: 23px;
      text-transform: lowercase;
      padding-right: 26px;
      padding-left: 0;
      width: max-content;

      &:first-letter {
        text-transform: uppercase;
      }

      &:hover {
        @extend .link-hover-state;
        background-image: icon('arrowRightIcon', $red);
        background-color: transparent;
      }

      &:active {
        @extend .link-active-state;
        background-image: icon('arrowRightIcon', $red);
      }
    }

    &:not([disabled]):hover:after {
      background-color: transparent;
      color: $primary-color-darker;
    }
  }
}
