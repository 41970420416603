footer {
  height: auto;
  min-height: 96px;
  background: $black;
  color: $white;
  font-family: $primary-font;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;

  a:not(.button) {
    color: inherit;
  }

  .site-footer-inner {
    padding: 40px 10px;

    @include mediumAndLarge {
      padding: 40px 43px;
    }

    p {
      margin: 0;
      display: block;
      text-align: left;
      color: inherit;
      font-size: inherit;
      font-family: $primary-font;
      font-weight: inherit;
      line-height: 13px;

      @include small {
        text-align: center;
      }
    }
  }
}

.mobile-footer {
  button {
    margin-top: 0;
  }
}
