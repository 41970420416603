form.resend-order {
  label {
    max-width: 560px;
  }
}

.below-resend-confirmation {
  margin-bottom: 32px;
}

.resend-route {
  .actions {
    flex-direction: column;
    justify-content: flex-start;

    button {
      width: max-content;
    }

    button.navigate-back {
      margin-top: $spacing-l;
    }
  }
}
