.tix-label {
  @extend .small-text-bold;
  text-transform: uppercase;
  margin: 4px 0;
}

.tix-input {
  border-width: 2px;
  border-color: $black;
  line-height: 44px;
}

.link-active {
  cursor: default;
}

.link-hover-state {
  color: $red;
}

.link-active-state {
  color: $red;
}

select {
  height: 48px;
}

.radio-group .radio-item.selectable {
  height: 48px;
}

.radio-item:not(.selected) input[type='radio']:hover {
  border-color: $medium-gray;
}

.checkbox > input[type='checkbox'] {
  border: 2px solid $black;
  width: 23px;
  height: 23px;
}

.checkbox > input[type='checkbox']:checked {
  background-color: $secondary-color;
}

//distance between form fields
.form-input-group {
  label.form-input:not(.checkbox):not(.radio-item) {
    margin-bottom: 30px;
  }
}

button {
  &.primary,
  &.secondary {
    &:enabled:hover {
      background-color: $medium-gray;
      color: $white;
    }
  }

  &.secondary {
    background-color: $white;
    color: $black;
    border: 2px solid $black;
  }
}

a.button:enabled:hover {
  background-color: $medium-gray;
  color: $white;
}

//back button
button.navigate-back {
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;

  &:before {
    -webkit-mask-image: icon('thinBackArrow');
  }

  &:hover {
    @extend .link-hover-state;
  }

  &:active {
    @extend .link-active-state;
  }
}

//messages
div.message {
  margin: $spacing-l 0;

  &.error {
    background-color: rgba($red, 0.1);
    border-color: rgba($red, 0.3);
    color: $black;

    &:before {
      background: $red;
    }
  }

  &.members-banner {
    &:before {
      font-size: 17px;
    }
  }
}

label.form-input:not(.checkbox):not(.radio-item) .label.message,
label.form-input:not(.checkbox):not(.radio-item) .message {
  margin-bottom: 0;
}

.stepper {
  border: none;

  .count {
    font-family: $primary-font;
    font-weight: 900;
    font-size: 16px;
    line-height: 18px;
  }

  > button {
    background-color: $white;
    border: 1px solid $primary-color;

    > svg {
      fill: $primary-color;
    }

    &.more,
    &.less {
      &:not([disabled]):hover {
        background-color: $medium-gray;
        border-color: transparent;

        > svg {
          fill: $white;
        }
      }
    }
  }
}

.section-header {
  h3 {
    margin-top: 0;
  }
}
