.site-content .content-detail-page {
  > h1 {
    font-size: 36px;
  }

  details {
    margin-top: 12px;
  }

  .description-wrapper {
    .subtitle {
      font-weight: bold;
      font-family: $primary-font;
      font-size: 18px;
      line-height: 24px;
      margin: 26px 0 0 0;

      @include mediumAndLarge {
        margin: 32px 0 0 0;
      }
    }

    > .description {
      margin: 18px 0 0 0;

      @include mediumAndLarge {
        margin: 26px 0 0 0;
      }

      p {
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .mobile-content-wrapper {
    .subtitle {
      margin-top: 18px;
    }

    .description-link {
      margin-bottom: 48px;
    }
  }

  .selection {
    margin-top: 0;
    padding-top: 0;

    > h2:first-of-type {
      font-family: $primary-font;
      font-size: 24px;
      line-height: 120%;
      font-weight: 700;
      margin-top: 0;
    }

    h3 {
      font-family: $primary-font;
      font-weight: bold;
      font-size: 15px;
      line-height: 24px;
      text-transform: uppercase;
    }

    .ticket-types > .ticket-type {
      font-size: 17px;
      line-height: 140%;
      border: 1px solid $light-gray;
      padding: 16px;
      margin-bottom: 8px;

      .ticket-type-description .title {
        font-family: $primary-font;
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
        display: block;
        margin-bottom: 5px;
      }
    }
  }

  .ticket-group {
    h2 {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      margin-top: 38px;
      border-bottom: none;
      margin-bottom: 4px;
      padding-bottom: 0;
    }

    .ticket-group-description p {
      margin: 0 0 8px 0;
    }
  }

  .form-input:not(.checkbox):not(.membership-level) h3.label {
    margin: $spacing-xs 0 $spacing-xs * 0.5 0;
  }

  @include mediumAndLarge {
    .main > form > .actions {
      margin-bottom: 98px;
    }
  }

  .admit-details-fields {
    > h3 {
      margin-bottom: 16px;
    }

    .admit-details-field-group {
      margin-bottom: 16px;
    }
  }
}

.applied-cards {
  .applied-card {
    button.remove {
      padding: 0 12px;
    }
  }
}

.content-detail-page.membership .select-tickets .section-header {
  margin-top: 8px;
}

.stepper {
  button,
  .count {
    width: 38px;
    height: 38px;
  }

  button {
    &[disabled] {
      color: black;
      opacity: 0.2;

      > svg {
        fill: $black;
      }
    }
  }

  .count {
    border: 0;
  }

  &.active {
    .count {
      color: $black;
      background-color: transparent;
    }
  }
}

.select-available-session,
.date-selector {
  .selectable-button {
    font-size: 16px;
    border: 2px solid $black;
    font-weight: 400;
    line-height: 18px;
    padding: 10px 12px;

    .availability-status {
      font-size: 17px;
    }

    &.available {
      @include hover {
        background-color: $medium-gray;
        border-color: $medium-gray;
        color: white;

        .availability-status {
          color: inherit;
        }

        .badge {
          outline: 1px solid white;
        }
      }
    }

    .session-time {
      font-weight: normal;
    }
  }
}

.select-available-session {
  &.has-prices.availability-visible {
    .session {
      .availability-status {
        font-size: 14px;
      }
    }
  }
}

.selected-date-time,
.selectable-button {
  font-family: $primary-font;
  font-size: 16px;
  line-height: 1.2;
}
