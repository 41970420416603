//Typography

body {
  font-family: $secondary-font;
  color: $black;
  font-size: 18px;
  line-height: 140%;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  overflow: auto;
}

.body-inner {
  .site-content-inner {
    padding-top: 0;
  }

  .has-sidebar .main {
    @include desktopExperience {
      padding-bottom: 0;
    }
  }
}

h1 {
  font-family: $primary-font;
  font-size: 48px;
  line-height: 110%;
  letter-spacing: -1px;
  font-weight: 700;
  margin: 67px 0 38px 0;

  @include small {
    font-size: 32px;
    line-height: 116%;
    margin: $spacing-m 0;
  }
}

h2 {
  font-family: $primary-font;
  font-size: 30px;
  line-height: 100%;
  font-weight: 700;
  margin: $spacing-l 0;
}

h3 {
  font-family: $primary-font;
  font-size: 24px;
  line-height: 120%;
  font-weight: 700;
  margin: $spacing-l 0;
}

h4 {
  font-family: $primary-font;
  font-size: 18px;
  line-height: 120%;
  font-weight: 700;
  margin: $spacing-l 0;
}

.site-content details > summary {
  font-size: 21px;
  font-family: $secondary-font;
}

.small-text {
  font-family: $primary-font;
  font-size: 11px;
  line-height: 13px;
}

.small-text-bold {
  @extend .small-text;
  font-weight: bold;
}

.route {
  margin-bottom: 32px;
}

a:not(.button) {
  text-decoration: underline;
  &:hover {
    @extend .link-hover-state;
  }
}

a.button,
button.primary,
button.secondary {
  text-transform: none;
  font-family: $primary-font;
  font-weight: normal;
}

//action separators
.checkout-route .main > .actions,
.content-detail-page .main .actions {
  @include topSeparator();
}
