.checkout-route {
  .form.checkout {
    max-width: unset;
  }

  .before-identity-form,
  .purchaser-details > h2 {
    margin-bottom: $spacing-m;

    @include small {
      font-size: 24px;
    }
  }

  .guest-details-form.form-input-group,
  .member-name-fields.form-input-group,
  .payment-method.form-input-group {
    max-width: 560px;
  }

  .payment-method.form-input-group {
    label.form-input:not(.checkbox):not(.radio-item) {
      @include small {
        margin-bottom: 0;
      }
    }
  }

  .upsell.radio-group {
    .radio-items {
      display: flex;

      label.radio-item {
        padding: 0 10px;
        background: $white;
        box-shadow: none;
        border-width: 2px;
        border-color: $black;
        height: 48px;
        line-height: 46px;

        &:hover {
          background-color: $medium-gray;
          color: $white;
        }

        .label {
          font-size: 16px;
          line-height: 24px;
          font-family: $primary-font;
        }

        &.selected {
          border-color: transparent;
          color: $white;
          background: $secondary-color;
        }
      }
    }
  }

  .guest-details-form.form-input-group,
  .member-name-fields.form-input-group {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;

    .form-input {
      width: 100%;
    }

    .city {
      margin-right: $spacing-l * 0.5;
      width: calc(60% - #{$spacing-l} / 4);
    }

    .state {
      width: calc(40% - #{$spacing-l} / 4);
    }

    .zipCode,
    .zip-code {
      margin-right: $spacing-l * 0.5;
      width: calc(30% - #{$spacing-l} / 4);
    }

    .country {
      width: calc(70% - #{$spacing-l} / 4);
    }
  }

  .form-wrapper > .actions,
  .form.checkout > :not(:first-child) {
    @include topSeparator($light-gray, 1px, solid, 32px, 38px);

    h2 {
      margin: 0 0 28px 0;
    }
  }

  .form.checkout > :first-child {
    h2 {
      margin-top: 0;
    }
  }

  .apply-gift-cards {
    margin-top: 0;
    font-family: $primary-font;
    font-size: 16px;

    @include small {
      .add-card {
        margin: 26px 0;
      }
    }

    .gift-card-number-field {
      max-width: 350px;
    }
  }

  button.apply-card {
    margin-top: 21px;
    padding: 0 6px;
    line-height: 44px;
    border: none;

    &:enabled:hover {
      background-color: transparent;
      color: $dark-gray;
    }
  }

  @include mediumAndLarge {
    .main > .actions {
      margin-bottom: 120px;
    }
  }
}
