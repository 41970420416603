section.cart {
  background: $very-light-gray;
  font-size: 24px;
  line-height: 120%;

  h2 {
    padding: 25px 0 20px 0;
    margin-top: 0;
    margin-left: 24px;
    margin-right: 24px;
    @include bottomSeparator($borderColor: $lighter-gray, $spacingAboveLine: 20px, $spacingBelowLine: 0);
    text-align: center;
    font-size: 24px;
    line-height: 120%;
  }

  > .content {
    > * {
      padding-left: $spacing-m;
      padding-right: $spacing-m;
    }

    .expiry-timer {
      padding-left: 0;
      padding-right: 0;

      .expires-in:not(.error):not(.warning) {
        background-color: $lighter-gray;
      }
    }

    .ticket-types {
      font-family: $secondary-font;
      font-size: 17px;
      line-height: 140%;
    }

    .ticket.line-item {
      font-size: 18px;
      line-height: 24px;

      header {
        font-family: $primary-font;
      }

      h4 {
        font-weight: 700;
      }

      .ticket-date {
        @extend .small-text-bold;
        text-transform: uppercase;
        margin: 9px 0 0 0;
      }
    }

    .ticket-wrapper {
      padding-top: 16px;

      .ticket {
        padding-bottom: 16px;
      }
    }

    .ticket-wrapper .remove-cart-item-overlay .actions button {
      height: 40px;
      min-height: auto;
      padding-top: 0;
      padding-bottom: 0;
    }

    .ticket-wrapper,
    .add-promo-button,
    .add-promo-form {
      padding-left: 0;
      padding-right: 0;
    }

    .apply-promo {
      margin-top: 0;
    }
  }

  //terrible selector to override the scope CSS without using !important
  .ticket-wrapper .ticket + div.actions > button.remove {
    font-size: 28px;
    margin: -12px;
  }

  .ticket header .price-wrapper {
    font-weight: bold;
  }

  .content .total-prices {
    background: $very-light-gray;
    font-size: 18px;
    line-height: 24px;
    padding-left: 24px;
    padding-right: 24px;
    font-family: $primary-font;

    @include mobileExperience {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 20px;
    }

    .subtotal {
      h3,
      .price-wrapper {
        font-family: $primary-font;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
      }
    }

    .total-due.line-item {
      h3,
      .price-wrapper {
        font-family: $primary-font;
        font-size: 21px;
        line-height: 29px;
      }
    }
  }

  form.actions {
    padding: 0;
  }

  .apply-promo-codes {
    border-color: $lighter-gray;

    input:not([type='radio']):not([type='checkbox']) {
      line-height: 36px;
    }

    button.apply-promo {
      &:enabled:hover {
        background-color: $medium-gray;
        color: $white;
      }
    }
  }

  .message {
    font-size: 18px;
  }

  //empty cart
  &.empty {
    border-bottom: 8px solid $black;
    .content {
      p {
        margin: 20px 0 0;
        font-size: 16px;
        line-height: 24px;
        padding-top: 0;
        padding-bottom: 20px;
        text-align: center;
      }
    }
  }

  button.primary.purchase {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    margin-top: 0;
  }
}

.modal {
  section.cart {
    .tickets {
      padding: 0;

      .ticket-wrapper {
        padding-left: $spacing-s;
        padding-right: $spacing-s;
      }
    }
  }
}

.mobile-footer {
  font-family: $primary-font;
}
