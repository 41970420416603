.site-content {
  .order-complete-route {
    .main {
      text-align: center;
      @include responsiveProperty('margin-bottom', $spacing-l, $spacing-l * 2, $spacing-l * 2);

      h1,
      ul.links {
        padding: 0;
        margin: 0 0 $spacing-l 0;
        font-family: $primary-font;

        @include small {
          margin-bottom: $spacing-l * 2;
        }

        li:not(:last-of-type) {
          margin-bottom: 16px;
        }

        a {
          font-size: 16px;
          line-height: 24px;

          &:hover {
            border-bottom-color: transparent;
            text-decoration: none;
          }
        }
      }

      h1 {
        max-width: unset;
        margin: 42px 0 $spacing-l 0;
        @include responsiveProperty('font-size', 24px, 26px, 30px);
        @include responsiveProperty('line-height', 28px, 32px, 36px);
        border: 0;

        span {
          font-size: 165%;
          line-height: 120%;
          display: block;
          margin-bottom: $spacing-xs;
        }
      }

      .complete-message {
        font-size: 21px;
        line-height: 140%;
      }

      code.order-number {
        display: block;
        line-height: 38px;
        font-size: 30px;
        font-family: $primary-font;
        font-weight: bold;

        @include small() {
          line-height: 24px;
          font-size: 20px;
        }
      }
    }
  }
}
