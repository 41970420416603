.modal {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.4);

  > header > span.title,
  > header > h1,
  div > h1 {
    @extend h2;
  }

  h1 {
    border: 0;
    margin-top: 0;
  }

  h2 {
    @extend h2;
    border: 0;
    margin-top: 0;
  }

  .form-input > .label {
    font-weight: bold;
  }

  > header > span.close {
    font-size: 32px;
    color: $secondary-color;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &.login {
    button.primary {
      margin-right: 50%;
    }

    h2 {
      font-size: 30px;
    }

    .actions a {
      width: 100%;
      text-align: center;
    }
  }

  &.membership-modal {
    .membership-modal-inner {
      @include large {
        padding-left: 16px;
      }

      > p {
        margin: 0;
      }
    }

    h2 {
      font-size: 22px;
    }

    .content {
      .label {
        font-family: $secondary-font;
        font-size: 21px;
        text-transform: none;
        line-height: 29.3px;
        margin: 0;
      }
    }

    .modal-body {
      @include large {
        display: flex;
        @include membershipModalImage {
          background-image: url('images/member-details-modal.jpg');
        }
      }
    }

    button {
      max-width: 304px;

      @include mobileExperience {
        max-width: none;
      }
    }
  }
}
